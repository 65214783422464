import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SiteMetaData = makeShortcode("SiteMetaData");
const Fragment = makeShortcode("Fragment");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const Message = makeShortcode("Message");
const Link = makeShortcode("Link");
const Badge = makeShortcode("Badge");
const Divider = makeShortcode("Divider");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const SourceList = makeShortcode("SourceList");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SiteMetaData mdxType="SiteMetaData">
      {siteMetadata => {
        const {
          name,
          description
        } = siteMetadata;
        return <Fragment mdxType="Fragment">
        <Heading variant='styles.h2' mdxType="Heading">about</Heading>
        <Text mdxType="Text">{description}</Text>
        </Fragment>;
      }}
    </SiteMetaData>
    <p>{`I'm a Security Engineer Working Since 2016. Ranked Top-100 Worldwide Bug Bounty Hunter for Multiple Years, Experienced Penetration Tester, Conference Speaker, And also a CTF player... `}</p>
    <Message variant='secondary' mb={2} mdxType="Message">
More about me can be found in my <Link href='https://drive.google.com/drive/folders/1eOtTgye6WN6fCOGqIOI-vLkf4S2mMPbm?usp=sharing' target='_blank' variant='styles.a' mdxType="Link"><Badge variant='secondary' sx={{
          m: 2
        }} mdxType="Badge">Resume</Badge></Link>
    </Message>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">  
  <Box sx={{
        width: ["100%", "100%", "55%"],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">         
        <h2>{`Contact`}</h2>
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">Number</Heading>
  <Link href='tel:+601161126472' variant='styles.a' mdxType="Link">(+60) 116-1126-472</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">Email</Heading>
  <Link href='mailto:xhzeem@gmail.com' variant='styles.a' mdxType="Link">contact@xhzeem.me</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">Twitter</Heading>
  <Link href='https://twitter.com/xhzeem/' target='_blank' variant='styles.a' mdxType="Link">https://twitter.com/xhzeem/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">LinkedIn</Heading>
  <Link href='https://www.linkedin.com/in/xhzeem/' target='_blank' variant='styles.a' mdxType="Link">https://linkedin.com/in/xhzeem/</Link>
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
        <h2>{`Profiles`}</h2>
  <Heading variant='styles.h5' sx={{
          mb: 0
        }} mdxType="Heading">GitHub</Heading>
  <Link href='https://github.com/xhzeem/' target='_blank' variant='styles.a' mdxType="Link">https://github.com/xhzeem/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h5' sx={{
          mb: 0
        }} mdxType="Heading">HackerOne</Heading>
  <Link href='https://hackerone.com/xhzeem/' target='_blank' variant='styles.a' mdxType="Link">https://hackerone.com/xhzeem/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h5' sx={{
          mb: 0
        }} mdxType="Heading">BugCrowd</Heading>
  <Link href='https://bugcrowd.com/xhzeem/' target='_blank' variant='styles.a' mdxType="Link">https://bugcrowd.com/xhzeem/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h5' sx={{
          mb: 0
        }} mdxType="Heading">HackTheBox</Heading>
  <Link href='https://app.hackthebox.eu/profile/222666/' target='_blank' variant='styles.a' mdxType="Link">https://hackthebox.eu/222666/</Link>
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
  </Box>
    </Flex>
    <h2>{`Latest Writeups`}</h2>
    <Divider mdxType="Divider" />
    <SourceList filter="posts" mdxType="SourceList">
  {posts => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).sort((a, b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)).splice(0, 2).map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              url,
              misc
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ["100%", "50%", "33.333%"],
            width: ["100%", "50%", "33.333%"]
          }} mdxType="Box">
            <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
              <Card sx={{
                mx: 2,
                p: 3,
                minHeight: "7em"
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant="styles.h4" sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
              </Card>
            </Link>
          </Box>;
        })}
        <Box sx={{
          maxWidth: ["100%", "50%", "33.333%"],
          width: ["100%", "50%", "33.333%"]
        }} mdxType="Box">
            <Link href="/posts/" sx={{
            textDecoration: 'none'
          }} mdxType="Link">
              <Card bg="muted" sx={{
              mx: 2,
              p: 3,
              minHeight: "7em"
            }} mdxType="Card"> 
                <Heading variant="styles.h4" sx={{
                color: 'text',
                my: 29,
                textAlign: 'center'
              }} mdxType="Heading">
                  Read more...
                </Heading>
              </Card>
            </Link>
          </Box>
    </Flex>}
    </SourceList>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">  
  <Box sx={{
        width: ["100%", "50%", "33.333%"]
      }} mdxType="Box">          
        <h2>{`Experience`}</h2>
  <Text mdxType="Text">Vigilant Asia</Text>
  <Text variant='styles.small' mdxType="Text">AUG 2021 - PRESENT</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">SeeCureApp</Text>
  <Text variant='styles.small' mdxType="Text">SEP 2017 - AUG 2021</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">Bug Hunting</Text>
  <Text variant='styles.small' mdxType="Text">Jan 2016 - Present</Text>
  <Divider mdxType="Divider" />
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "50%", "33.333%"]
      }} mdxType="Box">            
        <h2>{`CVEs`}</h2>
  <Text mdxType="Text">CVE-2022-26635</Text>
  <Text variant='styles.small' mdxType="Text">PHP-Memcached</Text>
  <Divider mdxType="Divider" />
  <Text mdxType="Text">CVE-2021-30151</Text>
  <Text variant='styles.small' mdxType="Text">Sidekiq</Text>
  <Divider mdxType="Divider" />
    
  <Text mdxType="Text">CVE-2017-3101</Text>
  <Text variant='styles.small' mdxType="Text">Adobe Connect</Text>
  <Divider mdxType="Divider" />
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "50%", "33.333%"]
      }} mdxType="Box">          
        <h2>{`Hall Of Fames`}</h2>
  <Badge variant='error' sx={{
          m: 2
        }} mdxType="Badge">Google</Badge>
  <Badge variant='secondary' sx={{
          m: 2
        }} mdxType="Badge">Meta</Badge>
  <Badge variant='success' sx={{
          m: 2
        }} mdxType="Badge">Microsoft</Badge>
  <Badge variant='primary' sx={{
          m: 2
        }} mdxType="Badge">Apple</Badge>
  <Badge variant='error' sx={{
          m: 2
        }} mdxType="Badge">Adobe</Badge>
  <Badge variant='secondary' sx={{
          m: 2
        }} mdxType="Badge">Twitter</Badge>
  <Badge variant='success' sx={{
          m: 2
        }} mdxType="Badge">Coinbase</Badge>
  <Badge variant='primary' sx={{
          m: 2
        }} mdxType="Badge">Yahoo</Badge>
  <Badge variant='error' sx={{
          m: 2
        }} mdxType="Badge">Xiaomi</Badge>
  <Badge variant='secondary' sx={{
          m: 2
        }} mdxType="Badge">Blockchain</Badge>
  <Badge variant='success' sx={{
          m: 2
        }} mdxType="Badge">Upwork</Badge>
  <Badge variant='primary' sx={{
          m: 2
        }} mdxType="Badge">Brave</Badge>  
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      